#title {
    background-color: #282c34;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#loginimg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    margin-top: 5%;
    margin-bottom: 10%;
    text-align: center;
}

#page{
    margin-right: 25%;
    margin-left: 25%;

}
